exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-admin-activation-manager-js": () => import("./../../../src/components/admin/activation_manager.js" /* webpackChunkName: "component---src-components-admin-activation-manager-js" */),
  "component---src-components-admin-admin-dashboard-js": () => import("./../../../src/components/admin/admin_dashboard.js" /* webpackChunkName: "component---src-components-admin-admin-dashboard-js" */),
  "component---src-components-admin-admin-login-js": () => import("./../../../src/components/admin/admin_login.js" /* webpackChunkName: "component---src-components-admin-admin-login-js" */),
  "component---src-components-admin-admin-login-test-js": () => import("./../../../src/components/admin/admin_login_test.js" /* webpackChunkName: "component---src-components-admin-admin-login-test-js" */),
  "component---src-components-admin-admin-search-individual-js": () => import("./../../../src/components/admin/admin_search_individual.js" /* webpackChunkName: "component---src-components-admin-admin-search-individual-js" */),
  "component---src-components-admin-admin-search-js": () => import("./../../../src/components/admin/admin_search.js" /* webpackChunkName: "component---src-components-admin-admin-search-js" */),
  "component---src-components-admin-general-stats-admin-general-stats-js": () => import("./../../../src/components/admin/general_stats/admin_general_stats.js" /* webpackChunkName: "component---src-components-admin-general-stats-admin-general-stats-js" */),
  "component---src-components-admin-my-profile-my-profile-js": () => import("./../../../src/components/admin/my_profile/my_profile.js" /* webpackChunkName: "component---src-components-admin-my-profile-my-profile-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-admissions-fast-entry-test-format-tips-js": () => import("./../../../src/pages/admissions/fast-entry-test-format-tips.js" /* webpackChunkName: "component---src-pages-admissions-fast-entry-test-format-tips-js" */),
  "component---src-pages-admissions-giki-application-process-js": () => import("./../../../src/pages/admissions/giki-application-process.js" /* webpackChunkName: "component---src-pages-admissions-giki-application-process-js" */),
  "component---src-pages-admissions-iba-admission-application-js": () => import("./../../../src/pages/admissions/iba-admission-application.js" /* webpackChunkName: "component---src-pages-admissions-iba-admission-application-js" */),
  "component---src-pages-admissions-lums-application-tips-js": () => import("./../../../src/pages/admissions/lums-application-tips.js" /* webpackChunkName: "component---src-pages-admissions-lums-application-tips-js" */),
  "component---src-pages-admissions-lums-freshman-campus-life-js": () => import("./../../../src/pages/admissions/lums-freshman-campus-life.js" /* webpackChunkName: "component---src-pages-admissions-lums-freshman-campus-life-js" */),
  "component---src-pages-admissions-nust-application-js": () => import("./../../../src/pages/admissions/nust-application.js" /* webpackChunkName: "component---src-pages-admissions-nust-application-js" */),
  "component---src-pages-admissions-nust-net-sample-js": () => import("./../../../src/pages/admissions/nust-net-sample.js" /* webpackChunkName: "component---src-pages-admissions-nust-net-sample-js" */),
  "component---src-pages-admissions-nust-net-tips-js": () => import("./../../../src/pages/admissions/nust-net-tips.js" /* webpackChunkName: "component---src-pages-admissions-nust-net-tips-js" */),
  "component---src-pages-admissions-oxbridge-applying-tips-js": () => import("./../../../src/pages/admissions/oxbridge-applying-tips.js" /* webpackChunkName: "component---src-pages-admissions-oxbridge-applying-tips-js" */),
  "component---src-pages-admissions-pak-army-application-js": () => import("./../../../src/pages/admissions/pak-army-application.js" /* webpackChunkName: "component---src-pages-admissions-pak-army-application-js" */),
  "component---src-pages-admissions-pieas-application-process-js": () => import("./../../../src/pages/admissions/pieas-application-process.js" /* webpackChunkName: "component---src-pages-admissions-pieas-application-process-js" */),
  "component---src-pages-admissions-uet-admission-ecat-js": () => import("./../../../src/pages/admissions/uet-admission-ecat.js" /* webpackChunkName: "component---src-pages-admissions-uet-admission-ecat-js" */),
  "component---src-pages-admissions-uk-ucas-application-checklist-js": () => import("./../../../src/pages/admissions/uk/ucas-application-checklist.js" /* webpackChunkName: "component---src-pages-admissions-uk-ucas-application-checklist-js" */),
  "component---src-pages-admissions-uk-ucas-application-js": () => import("./../../../src/pages/admissions/uk/ucas-application.js" /* webpackChunkName: "component---src-pages-admissions-uk-ucas-application-js" */),
  "component---src-pages-admissions-uk-ucas-personal-statement-js": () => import("./../../../src/pages/admissions/uk/ucas-personal-statement.js" /* webpackChunkName: "component---src-pages-admissions-uk-ucas-personal-statement-js" */),
  "component---src-pages-advice-complete-report-js": () => import("./../../../src/pages/advice/complete-report.js" /* webpackChunkName: "component---src-pages-advice-complete-report-js" */),
  "component---src-pages-advice-short-advice-js": () => import("./../../../src/pages/advice/short-advice.js" /* webpackChunkName: "component---src-pages-advice-short-advice-js" */),
  "component---src-pages-advice-user-advice-js": () => import("./../../../src/pages/advice/user-advice.js" /* webpackChunkName: "component---src-pages-advice-user-advice-js" */),
  "component---src-pages-advisory-board-js": () => import("./../../../src/pages/advisory-board.js" /* webpackChunkName: "component---src-pages-advisory-board-js" */),
  "component---src-pages-arab-test-js": () => import("./../../../src/pages/arab-test.js" /* webpackChunkName: "component---src-pages-arab-test-js" */),
  "component---src-pages-bu-js": () => import("./../../../src/pages/bu.js" /* webpackChunkName: "component---src-pages-bu-js" */),
  "component---src-pages-campus-life-lums-freshman-year-academics-js": () => import("./../../../src/pages/campus-life/lums-freshman-year-academics.js" /* webpackChunkName: "component---src-pages-campus-life-lums-freshman-year-academics-js" */),
  "component---src-pages-career-advice-choosing-university-guide-js": () => import("./../../../src/pages/career-advice/choosing-university-guide.js" /* webpackChunkName: "component---src-pages-career-advice-choosing-university-guide-js" */),
  "component---src-pages-career-advice-compatibility-test-js": () => import("./../../../src/pages/career-advice/compatibility-test.js" /* webpackChunkName: "component---src-pages-career-advice-compatibility-test-js" */),
  "component---src-pages-career-advice-job-title-importance-js": () => import("./../../../src/pages/career-advice/job-title-importance.js" /* webpackChunkName: "component---src-pages-career-advice-job-title-importance-js" */),
  "component---src-pages-career-advice-myths-busted-js": () => import("./../../../src/pages/career-advice/myths-busted.js" /* webpackChunkName: "component---src-pages-career-advice-myths-busted-js" */),
  "component---src-pages-career-advice-one-to-one-counselling-js": () => import("./../../../src/pages/career-advice/one-to-one-counselling.js" /* webpackChunkName: "component---src-pages-career-advice-one-to-one-counselling-js" */),
  "component---src-pages-career-opportunities-careers-in-biology-js": () => import("./../../../src/pages/career-opportunities/careers-in-biology.js" /* webpackChunkName: "component---src-pages-career-opportunities-careers-in-biology-js" */),
  "component---src-pages-career-opportunities-communication-skills-jobs-js": () => import("./../../../src/pages/career-opportunities/communication-skills-jobs.js" /* webpackChunkName: "component---src-pages-career-opportunities-communication-skills-jobs-js" */),
  "component---src-pages-career-opportunities-data-science-jobs-js": () => import("./../../../src/pages/career-opportunities/data-science-jobs.js" /* webpackChunkName: "component---src-pages-career-opportunities-data-science-jobs-js" */),
  "component---src-pages-career-opportunities-entrepreneurship-changing-world-js": () => import("./../../../src/pages/career-opportunities/entrepreneurship-changing-world.js" /* webpackChunkName: "component---src-pages-career-opportunities-entrepreneurship-changing-world-js" */),
  "component---src-pages-career-opportunities-gaming-jobs-js": () => import("./../../../src/pages/career-opportunities/gaming-jobs.js" /* webpackChunkName: "component---src-pages-career-opportunities-gaming-jobs-js" */),
  "component---src-pages-career-opportunities-maths-related-jobs-js": () => import("./../../../src/pages/career-opportunities/maths-related-jobs.js" /* webpackChunkName: "component---src-pages-career-opportunities-maths-related-jobs-js" */),
  "component---src-pages-career-opportunities-sports-job-prospects-js": () => import("./../../../src/pages/career-opportunities/sports-job-prospects.js" /* webpackChunkName: "component---src-pages-career-opportunities-sports-job-prospects-js" */),
  "component---src-pages-career-opportunities-teacher-jobs-js": () => import("./../../../src/pages/career-opportunities/teacher-jobs.js" /* webpackChunkName: "component---src-pages-career-opportunities-teacher-jobs-js" */),
  "component---src-pages-career-opportunities-trending-middle-east-js": () => import("./../../../src/pages/career-opportunities/trending-middle-east.js" /* webpackChunkName: "component---src-pages-career-opportunities-trending-middle-east-js" */),
  "component---src-pages-career-opportunities-trending-south-asia-js": () => import("./../../../src/pages/career-opportunities/trending-south-asia.js" /* webpackChunkName: "component---src-pages-career-opportunities-trending-south-asia-js" */),
  "component---src-pages-career-opportunities-working-from-home-js": () => import("./../../../src/pages/career-opportunities/working-from-home.js" /* webpackChunkName: "component---src-pages-career-opportunities-working-from-home-js" */),
  "component---src-pages-chat-js": () => import("./../../../src/pages/chat.js" /* webpackChunkName: "component---src-pages-chat-js" */),
  "component---src-pages-corona-updates-js": () => import("./../../../src/pages/corona-updates.js" /* webpackChunkName: "component---src-pages-corona-updates-js" */),
  "component---src-pages-ecat-preparing-tips-js": () => import("./../../../src/pages/ecat/preparing-tips.js" /* webpackChunkName: "component---src-pages-ecat-preparing-tips-js" */),
  "component---src-pages-faq-cs-vs-software-engineering-js": () => import("./../../../src/pages/faq/cs-vs-software-engineering.js" /* webpackChunkName: "component---src-pages-faq-cs-vs-software-engineering-js" */),
  "component---src-pages-faq-degree-importance-job-js": () => import("./../../../src/pages/faq/degree-importance-job.js" /* webpackChunkName: "component---src-pages-faq-degree-importance-job-js" */),
  "component---src-pages-faq-evidence-based-grading-cie-js": () => import("./../../../src/pages/faq/evidence-based-grading-cie.js" /* webpackChunkName: "component---src-pages-faq-evidence-based-grading-cie-js" */),
  "component---src-pages-faq-extra-subjects-o-a-level-js": () => import("./../../../src/pages/faq/extra-subjects-o-a-level.js" /* webpackChunkName: "component---src-pages-faq-extra-subjects-o-a-level-js" */),
  "component---src-pages-faq-ib-vs-a-level-js": () => import("./../../../src/pages/faq/ib-vs-a-level.js" /* webpackChunkName: "component---src-pages-faq-ib-vs-a-level-js" */),
  "component---src-pages-faq-o-level-vs-matric-js": () => import("./../../../src/pages/faq/o-level-vs-matric.js" /* webpackChunkName: "component---src-pages-faq-o-level-vs-matric-js" */),
  "component---src-pages-faq-studying-abroad-opportunities-benefits-js": () => import("./../../../src/pages/faq/studying-abroad-opportunities-benefits.js" /* webpackChunkName: "component---src-pages-faq-studying-abroad-opportunities-benefits-js" */),
  "component---src-pages-fb-privacy-policy-js": () => import("./../../../src/pages/fb-privacy-policy.js" /* webpackChunkName: "component---src-pages-fb-privacy-policy-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-graduates-cv-writing-tips-js": () => import("./../../../src/pages/graduates/cv-writing-tips.js" /* webpackChunkName: "component---src-pages-graduates-cv-writing-tips-js" */),
  "component---src-pages-graduates-job-interview-tips-js": () => import("./../../../src/pages/graduates/job-interview-tips.js" /* webpackChunkName: "component---src-pages-graduates-job-interview-tips-js" */),
  "component---src-pages-graduates-job-opportunities-covid-js": () => import("./../../../src/pages/graduates/job-opportunities-covid.js" /* webpackChunkName: "component---src-pages-graduates-job-opportunities-covid-js" */),
  "component---src-pages-graduates-networking-guide-js": () => import("./../../../src/pages/graduates/networking-guide.js" /* webpackChunkName: "component---src-pages-graduates-networking-guide-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-new-chat-js": () => import("./../../../src/pages/new-chat.js" /* webpackChunkName: "component---src-pages-new-chat-js" */),
  "component---src-pages-parents-guide-career-counselling-role-js": () => import("./../../../src/pages/parents-guide/career-counselling-role.js" /* webpackChunkName: "component---src-pages-parents-guide-career-counselling-role-js" */),
  "component---src-pages-parents-guide-post-covid-learning-js": () => import("./../../../src/pages/parents-guide/post-covid-learning.js" /* webpackChunkName: "component---src-pages-parents-guide-post-covid-learning-js" */),
  "component---src-pages-parents-guide-university-finances-cost-js": () => import("./../../../src/pages/parents-guide/university-finances-cost.js" /* webpackChunkName: "component---src-pages-parents-guide-university-finances-cost-js" */),
  "component---src-pages-pay-js": () => import("./../../../src/pages/pay.js" /* webpackChunkName: "component---src-pages-pay-js" */),
  "component---src-pages-professionals-js": () => import("./../../../src/pages/professionals.js" /* webpackChunkName: "component---src-pages-professionals-js" */),
  "component---src-pages-professionals-success-guide-js": () => import("./../../../src/pages/professionals/success-guide.js" /* webpackChunkName: "component---src-pages-professionals-success-guide-js" */),
  "component---src-pages-recover-js": () => import("./../../../src/pages/recover.js" /* webpackChunkName: "component---src-pages-recover-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-request-session-js": () => import("./../../../src/pages/request-session.js" /* webpackChunkName: "component---src-pages-request-session-js" */),
  "component---src-pages-sat-applying-and-preparing-tips-js": () => import("./../../../src/pages/sat/applying-and-preparing-tips.js" /* webpackChunkName: "component---src-pages-sat-applying-and-preparing-tips-js" */),
  "component---src-pages-sat-essay-tips-js": () => import("./../../../src/pages/sat/essay-tips.js" /* webpackChunkName: "component---src-pages-sat-essay-tips-js" */),
  "component---src-pages-sat-pak-universities-admissions-js": () => import("./../../../src/pages/sat/pak-universities-admissions.js" /* webpackChunkName: "component---src-pages-sat-pak-universities-admissions-js" */),
  "component---src-pages-search-university-js": () => import("./../../../src/pages/search-university.js" /* webpackChunkName: "component---src-pages-search-university-js" */),
  "component---src-pages-stats-js": () => import("./../../../src/pages/stats.js" /* webpackChunkName: "component---src-pages-stats-js" */),
  "component---src-pages-student-tips-4-resume-skills-js": () => import("./../../../src/pages/student-tips/4-resume-skills.js" /* webpackChunkName: "component---src-pages-student-tips-4-resume-skills-js" */),
  "component---src-pages-student-tips-activities-quarantine-time-js": () => import("./../../../src/pages/student-tips/activities-quarantine-time.js" /* webpackChunkName: "component---src-pages-student-tips-activities-quarantine-time-js" */),
  "component---src-pages-student-tips-fsc-exam-preparation-js": () => import("./../../../src/pages/student-tips/fsc-exam-preparation.js" /* webpackChunkName: "component---src-pages-student-tips-fsc-exam-preparation-js" */),
  "component---src-pages-student-tips-gap-year-useful-js": () => import("./../../../src/pages/student-tips/gap-year-useful.js" /* webpackChunkName: "component---src-pages-student-tips-gap-year-useful-js" */),
  "component---src-pages-student-tips-guide-for-olevels-alevels-js": () => import("./../../../src/pages/student-tips/guide-for-olevels-alevels.js" /* webpackChunkName: "component---src-pages-student-tips-guide-for-olevels-alevels-js" */),
  "component---src-pages-student-tips-no-internships-js": () => import("./../../../src/pages/student-tips/no-internships.js" /* webpackChunkName: "component---src-pages-student-tips-no-internships-js" */),
  "component---src-pages-student-tips-options-after-graduation-js": () => import("./../../../src/pages/student-tips/options-after-graduation.js" /* webpackChunkName: "component---src-pages-student-tips-options-after-graduation-js" */),
  "component---src-pages-student-tips-productivity-exams-cancelled-js": () => import("./../../../src/pages/student-tips/productivity-exams-cancelled.js" /* webpackChunkName: "component---src-pages-student-tips-productivity-exams-cancelled-js" */),
  "component---src-pages-student-tips-reading-leading-to-greatness-js": () => import("./../../../src/pages/student-tips/reading-leading-to-greatness.js" /* webpackChunkName: "component---src-pages-student-tips-reading-leading-to-greatness-js" */),
  "component---src-pages-student-tips-settling-new-uni-js": () => import("./../../../src/pages/student-tips/settling-new-uni.js" /* webpackChunkName: "component---src-pages-student-tips-settling-new-uni-js" */),
  "component---src-pages-student-tips-skills-to-learn-at-home-js": () => import("./../../../src/pages/student-tips/skills-to-learn-at-home.js" /* webpackChunkName: "component---src-pages-student-tips-skills-to-learn-at-home-js" */),
  "component---src-pages-student-tips-student-todo-list-js": () => import("./../../../src/pages/student-tips/student-todo-list.js" /* webpackChunkName: "component---src-pages-student-tips-student-todo-list-js" */),
  "component---src-pages-student-tips-woman-success-paths-js": () => import("./../../../src/pages/student-tips/woman-success-paths.js" /* webpackChunkName: "component---src-pages-student-tips-woman-success-paths-js" */),
  "component---src-pages-universities-pakistan-rankings-2021-js": () => import("./../../../src/pages/universities/pakistan-rankings-2021.js" /* webpackChunkName: "component---src-pages-universities-pakistan-rankings-2021-js" */),
  "component---src-pages-universities-women-unis-pakistan-js": () => import("./../../../src/pages/universities/women-unis-pakistan.js" /* webpackChunkName: "component---src-pages-universities-women-unis-pakistan-js" */),
  "component---src-pages-update-email-js": () => import("./../../../src/pages/update_email.js" /* webpackChunkName: "component---src-pages-update-email-js" */),
  "component---src-pages-updater-js": () => import("./../../../src/pages/updater.js" /* webpackChunkName: "component---src-pages-updater-js" */),
  "component---src-pages-useful-material-js": () => import("./../../../src/pages/useful-material.js" /* webpackChunkName: "component---src-pages-useful-material-js" */),
  "component---src-pages-user-advice-js": () => import("./../../../src/pages/user-advice.js" /* webpackChunkName: "component---src-pages-user-advice-js" */),
  "component---src-pages-user-verify-js": () => import("./../../../src/pages/user_verify.js" /* webpackChunkName: "component---src-pages-user-verify-js" */),
  "component---src-pages-verify-email-js": () => import("./../../../src/pages/verify_email.js" /* webpackChunkName: "component---src-pages-verify-email-js" */),
  "component---src-pages-your-advice-js": () => import("./../../../src/pages/your-advice.js" /* webpackChunkName: "component---src-pages-your-advice-js" */)
}

